import { Box, VStack } from "@efishery/onefish";
import React from "react";

interface BottomSheetProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  component: any;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen = false,
  setIsOpen,
  component,
}) => {
  return (
    <>
      <VStack
        position="fixed"
        bottom={isOpen ? "-10" : "-100%"}
        left="50%"
        transform={
          isOpen ? "translateX(-50%)" : "translateX(-50%) translateY(100%)"
        }
        maxWidth="480px"
        width="100%"
        bg="white"
        paddingBottom={10}
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        transition="bottom 0.3s ease"
        zIndex={1000}
      >
        <VStack width="100%" p={4} spacing={4} alignItems="center">
          <Box width="100%">{component()}</Box>
        </VStack>
      </VStack>

      {isOpen && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.5)"
          zIndex={999}
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
