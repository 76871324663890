import { useEffect, useState } from "react";
import { dataProvider } from "src/libs";
import {
  BaseRecords,
  ErrorResponse,
  GetOneResponse,
} from "src/libs/dataProvider/types";

import { IUseOneProps, IUseOneReturnValue } from "./type";

export function useOne<TData extends BaseRecords = BaseRecords>({
  apiUrl,
  resource,
  id,
  meta,
}: IUseOneProps): IUseOneReturnValue<TData> {
  const { getOne } = dataProvider(apiUrl);
  const [data, setData] = useState<GetOneResponse<TData> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isError, setIsError] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const fetchOneData = async () => {
    setIsLoading(true);
    const getOneData = await getOne<TData>({
      resource,
      id,
      meta,
    });
    const fetchError = Boolean((getOneData.data as ErrorResponse).error);
    setIsSuccess(() => (fetchError ? false : true));
    setIsError(() => (fetchError ? true : false));
    setData(getOneData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOneData();
  }, [refetch]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch: () => setRefetch(!refetch),
  };
}
